@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wdth,wght@125,900&display=swap");
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

$primary: #7bea19;
$secondary: #74b637;
$tertiary: #74b61b;
$dark: #05111d;
$red: #e32828;

// Components and Head

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extra-bold {
  font-weight: 800;
}

.font-black {
  font-family: "Archivo", sans-serif;
  font-weight: 900;
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary !important;
}

.color-gray {
  color: #989898;
}

.color-dark {
  color: $dark !important;
}

.color-white {
  color: white !important;
}

.color-red {
  color: #e32828;
}

.color-blue {
  color: #2678c0 !important;
}

.text-left {
  text-align: start;
}

.text-right {
  text-align: end;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.9375rem;
  color: #fff;
  line-height: 1.1;
  letter-spacing: 0px;
}

h1 {
  font-size: 64px;
}

h1.text-lg {
  font-size: 90px;
}

h1.title-products {
  font-size: 110px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}

h6.subtitle {
  font-size: 18px;
}

p.contract {
  font-size: 0.7rem;
}

.section-height-100 {
  min-height: 70vh;
}

.section-height-100-center {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding-container {
  padding: 8rem 0rem;
}

.padding-row {
  padding-top: 6rem;
}

img.logo {
  height: 39px;
}

img.img-stretch {
  object-fit: cover;
}

img.selo-img {
  max-width: 25%;
}

.flex-end {
  align-self: flex-end;
}

hr {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid #525252;
  margin: 1em 0;
  padding: 0;
}

hr.footer {
  display: block;
  width: 7rem;
  border-top: 2px solid $tertiary !important;
  opacity: 0.9;
}

.margin-rigth {
  margin-right: 2rem;
}

.nav-menu .nav-link {
  font-size: 13px;
  color: #fff !important;
  font-weight: 600;
  margin: auto 12px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.bg-color-primary {
  background-color: $primary;
}

.bg-color-secondary {
  background-color: $secondary;
}

.bg-dark-transition {
  background-color: #000;
  transition: 0.7s;
}

.bg-dark-green-transition {
  background: transparent linear-gradient(125deg, #05111d 0%, #74b637 100%) 0% 0% no-repeat padding-box;
  transition: 0.7s;
}

.bg-dark-sb {
  background-color: #05111d;
}

.bg-dark-green {
  background: transparent linear-gradient(125deg, #05111d 0%, #74b637 100%) 0% 0% no-repeat padding-box;
}

.bg-siteblindado {
  background: transparent linear-gradient(125deg, #05111d 0%, #74b637 100%) 0% 0% no-repeat padding-box;
}

.bg-vtex {
  background: transparent linear-gradient(125deg, #090a23 0%, #ff0066 100%) 0% 0% no-repeat padding-box;
}

.bg-linx {
  background: transparent linear-gradient(125deg, #2f003b 0%, #ffab00 100%) 0% 0% no-repeat padding-box;
}

.bg-dark-red {
  background: transparent linear-gradient(125deg, #05111d 0%, #e32828 100%) 0% 0% no-repeat padding-box;
}

.bg-dark-blue {
  background: transparent linear-gradient(125deg, #05111d 0%, #2678c0 100%) 0% 0% no-repeat padding-box;
}

.bg-dark-gray {
  background: transparent linear-gradient(125deg, #05111d 0%, #505050 100%) 0% 0% no-repeat padding-box;
}

.form-label {
  font-size: 12px;
  color: white;
  font-weight: 400;
}

.form-check-label {
  color: white;
}

.self-checkout .form-label,
.self-checkout .form-check-label {
  color: black;
}

input.form-control,
textarea.form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
}

.menu-dropdown:after,
.menu-dropdown a:after {
  display: none !important;
}

.menu-dropdown.highlighted {
  color: #fff;
  background: transparent linear-gradient(128deg, $secondary 0%, #87ff19 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0px;

  &:hover {
    background: $primary;
    color: $dark;
    transition: 0.8s;
  }
}

.btn {
  border-radius: 5px;
  border: 0px;
  height: 40px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2.4px;
}

.btn-form {
  width: 160px;
  height: 40px;
  border-radius: 5px;
}

.btn-green {
  color: #fff;
  background: transparent linear-gradient(128deg, $secondary 0%, #87ff19 100%) 0% 0% no-repeat padding-box;

  &:hover {
    background: $primary;
    color: $dark;
    transition: 0.8s;
  }

  &:disabled {
    background: transparent linear-gradient(128deg, $black 0%, #dedede 100%) 0% 0% no-repeat padding-box;
  }
}

.btn-green-dark {
  color: #fff;
  background: transparent linear-gradient(126deg, $secondary 0%, #87ff19 100%) 0% 0% no-repeat padding-box;

  &:disabled {
    background: transparent linear-gradient(128deg, $black 0%, #dedede 100%) 0% 0% no-repeat padding-box;
  }
}

.btn-search-height {
  height: 55px !important;
}

.btn-black {
  background: #000000 0% 0% no-repeat padding-box;

  &:hover {
    background-color: #87ff19;
    color: $dark;
    transition: 0.8s;
  }
}

.arrow-button-green {
  & a {
    text-decoration: none;
  }

  & svg {
    font-size: 20px;
    color: $primary !important;
    margin-right: 1.2rem;
  }
}

.arrow-button-black {
  & h6 {
    color: $dark;
    letter-spacing: 3px;
  }

  & a {
    text-decoration: none;
  }

  & svg {
    font-size: 20px;
    color: $primary !important;
    margin-right: 0.8rem;
  }
}

.arrow-button-white {
  & h6 {
    color: $white;
    letter-spacing: 3px;
  }

  & a {
    text-decoration: none;
  }

  & svg {
    font-size: 20px;
    color: $white !important;
    margin-right: 0.8rem;
  }
}

.text-decoration-none {
  text-decoration: none;
  color: inherit;
}

.menu-footer {
  display: block;
  color: #989898;
  font-size: 12px;
  margin-bottom: 10px;
}

.menu-footer:hover,
.menu-footer a:hover {
  color: white;
  font-weight: 500;
}

.w-fit-content {
  width: fit-content;
}

.card.card-blog-news {
  border: 1px solid #d6d6d6;
  border-radius: 0px 0px 20px 20px;

  & .description-news {
    padding: 2rem;
  }

  & img {
    height: 270px;
    width: 100%;
  }

  height: 100%;
}

.page-item {
  padding: 0rem 0.5rem;
}

.page-link {
  border-radius: 10px !important;
  width: 49px;
  height: 49px;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d6d6d6;
  color: $dark;
}

.page-item.active .page-link {
  background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  color: white;
}

.c-pointer {
  cursor: pointer;
}

// Pages

/// Home
.home-sess1 {
  background-image: url("../img/bg-home-sess1.png");
  background-size: cover;
}

span.color-gradient {
  background: transparent linear-gradient(90deg, $secondary 30%, $dark 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-sess2 {
  background: transparent linear-gradient(125deg, $dark 0%, $secondary 100%) 0%;
}

.card-home-verify {
  background: transparent linear-gradient(92deg, #7edc28 0%, #3f6e14 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 4rem 2rem;
}

.bg-experiences {
  background-image: url("../img/home/bg-experiences.jpg");
  background-size: cover;
  padding: 6rem 4rem;
}

.card-group.card-group-clients {
  .card {
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & .active-card {
      height: 6px;
      width: 100%;
      background: transparent linear-gradient(92deg, #74b637 0%, #06131e 100%) 0% 0% no-repeat padding-box;
      position: absolute;
      border-radius: 0px;
      top: 0;
    }
  }

  & div:nth-child(1) {
    border-radius: 0px 0px 0px 20px;
  }

  & div:last-child {
    border-radius: 0px 0px 20px 0px;
  }
}

.card.card-instagram {
  border-radius: 10px;
  width: 100%;
  height: 270px;
  border-width: 0px;
  overflow: hidden !important;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  &:hover {
    background: rgba(123, 234, 25, 0.8);

    img {
      opacity: 0.5 !important;
      animation: pulse 1.5s forwards;
    }
  }
}

// Products
.products-sess1 {
  background: transparent linear-gradient(125deg, #05111d 0%, #74b637 100%) 0% 0% no-repeat padding-box;
  padding: 0rem 2rem;
}

.title-outline-green {
  -webkit-text-stroke: 1px $primary;
  -webkit-text-fill-color: transparent;
}

.description-product-sess1 {
  padding-right: 20rem;
}

.products-background-dark-green {
  background: transparent linear-gradient(149deg, #05111d 0%, #74b637 100%) 0% 0% no-repeat padding-box;
}

.card.card-products {
  cursor: pointer;
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 10px;
  border-width: 0px;
  padding: 4rem 2rem 1.5rem 2rem;
  height: 100%;

  &:hover {
    background: transparent linear-gradient(147deg, #05111d 0%, #87ff19 100%) 0% 0% no-repeat padding-box;
    color: white;
    transition: 0.5s;

    & h6,
    h3 {
      color: white !important;
      transition: 0.5s;
    }
  }
}

// Consumidor

.bullet-point {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #7bea19;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  & div {
    width: 18px;
    height: 18px;
    background: #7bea19;
    border-radius: 50%;
  }
}

img.shield {
  height: 94px;
  margin-right: 1.5rem;
}

.diver {
  height: 5px;
  width: 100%;
  background: transparent linear-gradient(87deg, #ffffff 0%, #87ff19 100%) 0% 0% no-repeat padding-box;
  border-radius: 50px;
  margin-bottom: 1.5rem;
}

.card.card-guia {
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 10px;
  padding: 2rem;
  border: 0px;
  height: 100%;

  &:hover {
    background: transparent linear-gradient(147deg, #05111d 0%, #87ff19 100%) 0% 0% no-repeat padding-box;
    color: white;
    transition: 0.5s;

    & .diver {
      background: transparent linear-gradient(87deg, #05111d 0%, #87ff19 100%) 0% 0% no-repeat padding-box;
    }
  }
}

.card.card-form-verifique {
  background: transparent linear-gradient(92deg, #7edc28 0%, #3f6e14 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 4rem 2rem;

  & .form-verifique-aqui {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    border-width: 0px;
    text-align: center;
  }

  & .input-group-append button {
    background: #000000 0% 0% no-repeat padding-box;
    border-width: 0px;
    border-radius: 0px 5px 5px 0px;
    text-align: center;
    height: 100%;
  }
}

ul.list-how-to-know {
  color: white;
  font-size: 16px;

  & li {
    margin-bottom: 1rem;
  }
}

.title-card {
  background: transparent linear-gradient(112deg, #05111d 0%, #74b61b 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 100%;
  padding: 1rem 4rem;
  margin-bottom: 1rem;
}

ul.more-advices {
  color: $dark;

  & li::marker {
    color: $primary;
  }
}

.card.card-download-contract {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  border-width: 0px;
  border-radius: 10px;
  align-items: center;
  padding: 2rem;
  font-size: 80px;
  height: 100%;

  & svg {
    color: $dark;
  }

  &:hover {
    background: transparent linear-gradient(153deg, #05111d 0%, #87ff19 100%) 0% 0% no-repeat padding-box;

    & svg {
      color: white;
      transition: 0.3s;
    }

    & h5 {
      color: white !important;
      transition: 0.5s;
    }

    & h6 {
      color: white !important;
      transition: 0.5s;
    }
  }
}

.lock-bg {
  background-image: url("../img/consumer/lock.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.card-verify {
  background: transparent;
  position: absolute;

  &.card-verify-active {
    border: solid 2px #2678c0;
    width: 50%;

    & .icon {
      background-color: #2678c0;
      color: white;
      font-size: 30px;
    }
  }

  &.card-verify-inactive {
    border: solid 2px $red;
    width: 40%;

    & .icon {
      background-color: $red;
      color: white;
      font-size: 30px;
    }
  }
}

.swal-text {
  text-align: center;
}

// Blog
.input-group {
  & input {
    border: 1px solid #d6d6d6;
    border-radius: 5px 0px 0px 5px;
    font-size: 12px;
    color: #d6d6d6;
    border-width: 1px 0px 1px 1px;
  }

  & button {
    border: 1px solid #d6d6d6;
    border-radius: 0px 5px 5px 0px;
    font-size: 18px;
    color: #d6d6d6;
    border-width: 1px 1px 1px 0px;
  }
}

.card.card-categories {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 10px;
  border-width: 0px;
  padding: 2rem 1.5rem;
}

.card.card-post-instagram {
  border-radius: 10px;
  width: 100%;
  height: 140px;
  border-width: 0px;
  overflow: hidden !important;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  &:hover {
    background: rgba(123, 234, 25, 0.8);

    img {
      opacity: 0.5 !important;
      animation: pulse 1.5s forwards;
    }
  }
}

.img-blog {
  overflow: hidden !important;

  & img {
    object-fit: cover;
  }

  &:hover {
    background: rgba(123, 234, 25, 0.3);

    & img {
      opacity: 0.5 !important;
      animation: pulse 1s forwards;
    }
  }
}

.avatar-image {
  width: 60px;
  height: 60px;

  & img {
    border-radius: 50%;
    border: 2px solid #dedede;
  }
}

.body-blog {

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark;
    margin: 3rem 0rem 2rem 0rem;
  }

  & p img {
    text-align: center;
  }
}

// Sobre

.slick-news img {
  max-height: 50px;
}

.slick-clients img {
  max-height: 74px;
}

.card.clients-about-us {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 5px #00000029;
  border-width: 0px;
  border-radius: 5px;
  opacity: 1;
  padding: 2.5rem 2rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 210px !important;

  &:hover {
    background-color: $primary;
    color: white;
    transition: 0.5s;
  }
}

.bg-about-woman {
  background-image: url("../img/about/bg-woman.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 8rem 4rem 12rem 4rem;
}

.slick-testimonial {
  & .slick-dots li.slick-active button:before {
    color: #dedede;
  }

  & .slick-dots li button:before {
    color: white;
  }
}

.slick-testimonial-home {
  & .slick-dots li.slick-active button:before {
    font-size: 15px;
    color: #7deb19;
  }

  & .slick-dots li button:before {
    font-size: 15px;
    color: #cacaca;
  }
}

.card.send-testimonial {
  padding: 5rem 3rem;
  width: 465px;
  border-radius: 10px;
  border-width: 0px;
  background-image: url("../img/about/bg-send-testimonial.png");
  position: absolute;
  right: 10%;
}

.card.pentest-info {
  padding: 4rem 6rem;
  width: 800px;
  border-radius: 10px;
  border-width: 0px;
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.card.bg-dark-green {
  color: white;
  background: transparent linear-gradient(125deg, #05111D 0%, #009800 100%) 0% 0% no-repeat padding-box;
}

// .instagram-banner {
//   overflow: hidden !important;
//   border-radius: 10px;

//   &:hover {
//     background: rgba(123, 234, 25, 0.8);

//     img {
//       opacity: 0.5 !important;
//       animation: pulse 1.5s forwards;
//     }
//   }
// }

// Selo

.icon-seal {
  background-color: $primary;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.divisor-left {
  border-left: solid 0.1rem #e3e3e3;

  & img {
    width: 50%;
  }
}

// Footer

.social-media {
  position: relative;
  color: #fff;
  font-size: 26px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.social-media:hover {
  & a {
    color: $primary !important;
    transition: 0.4s;
  }

  border: 1px solid $tertiary !important;
  transition: 0.8s;
}

.social-media a {
  color: #fff !important;
}

.card-lgpd {
  bottom: 5px;
  display: flex;
  justify-content: center;

  & .card {
    padding: 20px;
    width: 70%;
    box-shadow: rgb(170 170 170) 0px 0px 10px;
    border: 0px;
  }
}

// Loading
.loading {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);

  &:before,
  &:after,
  div {
    width: 400px;
    height: 400px;
    top: 50%;
    left: 50%;
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 6px solid $primary;
  }

  &:before {
    content: "";
    animation: scale 1s ease-in infinite;
  }

  &:after {
    content: "";
    animation: scale 2s ease-in infinite;
  }

  div {
    animation: scale 3s ease-in infinite;
  }
}

.loading.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes scale {
  from {
    transform: translate(-50%, -50%) scale(0, 0);
  }

  to {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

@keyframes pulse {
  100% {
    transform: scale(1.4);
  }
}

@keyframes dropDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 900px) {
  .menu-dropdown.highlighted {
    margin-left: 1.3rem;
  }

  .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.7);

    &.show {
      animation: dropDown 300ms ease-in-out forwards;
    }

    .dropdown-item {
      color: #212529;
      padding-left: 25px;

      &:hover,
      :focus,
      :active {
        color: #1e2125;
      }
    }
  }
}

@media (max-width: 1680px) {
  // Pages

  /// Products
  h1.title-products {
    font-size: 80px;
  }

  .description-product-sess1 {
    padding-right: 10rem;
  }

  h1.text-lg {
    font-size: 70px;
  }
}

@media (max-width: 1440px) {
  // Pages

  /// Products
  h1.title-products {
    font-size: 70px;
  }

  .description-product-sess1 {
    padding-right: 7rem;
  }
}

@media (max-width: 1280px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 42px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 16px;
  }

  // Pages

  /// Products
  h1.title-products {
    font-size: 60px;
  }

  .description-product-sess1 {
    padding-right: 4rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.25rem !important;
  }

  h1.text-lg {
    font-size: 3.25rem !important;
  }

  h2 {
    font-size: 2rem !important;
  }

  h3 {
    font-size: 1.75rem !important;
  }

  h4 {
    font-size: 1.5rem !important;
  }

  h5 {
    font-size: 1.25rem !important;
  }

  h6 {
    font-size: 1rem !important;
  }

  .collapse.show {
    background-color: #000;
  }

  .menu-dropdown {
    &.highlighted {
      margin-top: 0.75rem;
    }
  }

  .menu-dropdown.nav-item.show.dropdown {
    text-align: center;

    & .dropdown-menu.show {
      background-color: #dedede;
      text-align: center;
      animation: dropDown 300ms ease-in-out forwards;

      a {
        font-size: 14px;
      }
    }
  }

  .padding-container {
    padding: 3rem 0rem;

    &.join-home {
      padding-bottom: 0px;
    }
  }

  .padding-row {
    padding-top: 2rem;
  }

  .mobile-height-100 {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .padding-top-mobile {
    padding-top: 10rem;
  }

  .text-center-mobile {
    text-align: center;
  }

  hr.footer {
    margin: auto;
  }

  .break-line {
    word-break: break-all;
  }

  // Pages

  /// Products

  /// Consumer

  .card-verify {
    &.card-verify-active {
      width: 94%;
    }

    &.card-verify-inactive {
      width: 94%;
    }
  }

  .home-landingpage-banner {
    padding: 2rem !important;
  }
}

@media (max-width: 991px) {
  .home-landingpage-banner {
    padding: 6rem !important;
  }
}
